<template>
  <path
    class="cls-1 planta--1"
    d="M1001.58,1013.36c1.68-10.63,17.07-11.51,51.59,11,24.5,15.97,44.37,45.11,53.85,60.69-1.22-6.66-2.6-13.28-4.19-19.87-4.3-17.83-10.25-35.38-18.09-52.06-26.78-33.15-67.16-88.41-62.72-96.29,5.01-8.89,19.01-4.83,42.35,25.95,23.34,30.78,29.88,81.87,29.88,81.87-.04-.05-.09-.1-.13-.15,3.94,9.76,7.3,19.76,10.1,29.93-2.33-17.47-3.33-38.85,1.23-57.28,9.69-39.14,21.64-48.54,30.31-41.81,8.58,6.66-12.23,92.7-24.24,132.11,.42,2.48,.81,4.97,1.18,7.46,.22,.41,.34,.63,.34,.63-.08-.05-.18-.1-.26-.15,2.37,15.9,3.79,31.87,4.47,47.61,.41,9.37,.5,18.75,.45,28.13,2.91-21.47,8.86-48.52,21.11-69.6,24.02-41.37,40.77-48.27,48.43-37.9,7.51,10.17-43.65,99.58-70.64,141.08-1.43,23.53-4.12,46.99-7.94,70.22,7.39-23.9,19.55-54.29,37.98-76.34,35.09-41.97,55.28-46.62,61.89-33.55,6.43,12.73-67.73,102.7-106.37,144.57-1.05,4.92-2.19,9.82-3.47,14.68-.63,2.4-4.57,1.76-3.93-.66,.35-1.31,.65-2.63,.98-3.95-32.13-46.74-93.92-148.43-85.69-160.15,8.37-11.93,27.69-4.3,56.51,42.5,23.19,37.67,29.06,92.51,30.47,112.48,5.9-24.72,9.66-50.12,12.51-75.29,.99-8.79,1.83-17.63,2.51-26.5-35.05-36.36-102.39-114.33-97.09-125.56,5.44-11.53,23.28-7.68,55.19,28.76,24.27,27.71,37.47,70.92,42.41,90.02,2.53-37.69,1.84-75.85-4.22-113.17-37.55-22.2-108.37-69.24-106.76-79.43Z"
  />
  <path
    class="cls-1 planta--1"
    d="M288.82,501.05c6.33-8.7-3.93-20.2-44.25-28.7-28.62-6.03-63.27,.52-80.99,4.84,5.57-3.84,11.23-7.55,17.01-11.09,15.65-9.56,32.26-17.77,49.6-24.02,42.37-4.51,110-15.03,112.43-23.74,2.74-9.83-10.02-16.86-48.29-11.6-38.27,5.26-79.02,36.76-79.02,36.76,.06,0,.13-.01,.2-.02-9.68,4.12-19.13,8.81-28.31,14.02,14-10.7,29.82-25.12,39.63-41.38,20.83-34.52,19.03-49.62,8.13-51-10.78-1.36-56.91,74.2-76.28,110.56-2.06,1.46-4.09,2.95-6.12,4.44-.44,.13-.68,.21-.68,.21,.09,.02,.2,.05,.29,.08-12.92,9.56-25.22,19.86-36.83,30.5-6.92,6.34-13.62,12.9-20.21,19.58,13.12-17.23,28.04-40.57,34.29-64.14,12.27-46.24,5.31-62.96-7.45-61.04-12.5,1.88-39.54,101.28-49.81,149.71-15.63,17.65-30.31,36.14-44.04,55.27,11.67-22.13,24.57-52.21,27.12-80.84,4.87-54.49-6.13-72.05-20.03-67.49-13.55,4.45-24.73,120.51-27.01,177.44-2.73,4.22-5.4,8.49-7.93,12.83-1.25,2.14,1.98,4.48,3.24,2.32,.68-1.17,1.4-2.32,2.1-3.49,55.77-10.33,171.36-38.55,173.84-52.65,2.52-14.36-16.54-22.62-70.01-9.91-43.03,10.23-85.96,44.86-101.08,57.99,13.31-21.66,28.61-42.27,44.39-62.08,5.51-6.92,11.17-13.76,16.96-20.51,50.49-.93,153.24-8.44,157.43-20.13,4.31-12-11.03-21.89-59.36-18.69-36.76,2.43-76.64,23.65-93.64,33.67,24.86-28.44,52.33-54.94,83.01-77.04,42.25,10.85,125.59,27.67,131.65,19.32Z"
  />
</template>
<script>
import { animacionBloqueVerde } from "@/mixins/AnimacionBloqueVerde.js";
export default {
  name: "svg plantas",
  mixins: [animacionBloqueVerde],
};
</script>