import { gsap } from "gsap";

export const animacionBloqueVerde = {
    mounted() {
        this.animacion();
    },
    methods: {
        animacion() {
            gsap.to(".planta--1", {
                rotateZ: 5,
                duration: 2,
                repeat: -1,
                yoyo: true,
            });
            gsap.to(".haba--1,.haba_borde--1", {
                x: 15,
                y: 50,
                duration: 2,
                repeat: -1,
                yoyo: true,
            });
            gsap.to(".haba--2,.haba_borde--2", {
                x: 0,
                y: -50,
                duration: 2,
                repeat: -1,
                yoyo: true,
            });
            gsap.to(".habita", {
                x: "random(-100, 100)",
                y: "random(-100, 100)",
                duration: 2,
                repeat: -1,
                repeatRefresh: true,
            });
        },
    },
}